import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ArrowLeftLanguageIconComponent,
  CheckIconComponent,
  CopyIconVer2Component,
  RedirectIconVer2Component,
} from 'src/assets/icons/IconComponent';
import { PATHS } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { useCopy } from 'src/hooks/useCopy';
import './mobile-menu-detail-content.scss';

export interface IWalletDetailContent {
  address: string;
  onClose: () => void;
  onClickItem: () => void;
}

const WalletContent: React.FC<IWalletDetailContent> = ({ address, onClose, onClickItem }) => {
  const history = useHistory();
  const location = useLocation();
  const { copy, copied } = useCopy();

  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const items: any[] = [];

  useEffect(() => {
    if (copied) return setIsOpen(true);
    return setIsOpen(false);
  }, [copied]);

  const renderContent = () => {
    return (
      <div className={`mobile-menu-detail ${userTheme}`}>
        <div className="mobile-menu-detail__item-wrapper">
          <div className="mobile-menu-detail__item-wrapper__item pt-20 justify-between">
            <div onClick={onClose}>
              <Icon className="back-icon" component={ArrowLeftLanguageIconComponent} />
              <span className="wallet-address">{ellipseAddress(account, 6, 4)}</span>
            </div>
            <div className="icon-group">
              <Tooltip title={'Copied'} open={isOpen}>
                {copied ? (
                  <Icon className="wallet-detail-icon mr-16" component={CheckIconComponent} />
                ) : (
                  <Icon
                    className="wallet-detail-icon mr-16"
                    component={CopyIconVer2Component}
                    onClick={() => copy(account)}
                  />
                )}
              </Tooltip>
              <Icon
                className="wallet-detail-icon"
                component={RedirectIconVer2Component}
                onClick={() => window.open(`https://testnet.bscscan.com/address/${account}`)}
              />
            </div>
          </div>
        </div>

        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={`mobile-menu-detail__item-wrapper ${item.className}`}
              onClick={() => {
                onClickItem();
              }}
            >
              <div
                className={`mobile-menu-detail__item-wrapper__item default-item justify-flex-start ${
                  location.pathname.includes('') &&
                  location.pathname.includes(item.queryParam) &&
                  'active'
                }`}
              >
                <Icon className="mr-16" component={item.icon} />
                <span>{item.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default WalletContent;

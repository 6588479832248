import { Key, PublicNonces, SignatureOutput } from '@borislav.itskov/schnorrkel.js';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import secureLocalStorage from 'react-secure-storage';
import { decrypt, encrypt, safeJSONParse } from 'src/helpers/encryption';
import { useAuth } from 'src/libs/rainbow';
import { useSetCombinePublicKey } from 'src/services/auth/set-combined-public-key';
import { useAccount, useChainId } from 'wagmi';
import Signer, { generateRandomKeys } from '../services/auth/key-pair';

const LOCAL_STORAGE_KEY = 'signerData';
const MAX_DECRYPTION_ATTEMPTS = 3;
const COOLDOWN_PERIOD = 60000; // 1 minute in milliseconds

interface SignerContextProps {
  signer: Signer | null;
  signerLoaded: boolean;
  getAddress: () => string;
  getPublicKey: () => Key | undefined;
  getPublicNonces: () => PublicNonces | undefined;
  multiSignMessage: (
    msg: string,
    publicKeys: Key[],
    publicNonces: PublicNonces[],
    signerData: Signer,
  ) => SignatureOutput | undefined;
}

interface SignerProviderProps {
  children: ReactNode;
}

const SignerContext = createContext<SignerContextProps | undefined>(undefined);

export const SignerProvider: React.FC<SignerProviderProps> = ({ children }) => {
  const [signer, setSigner] = useState<Signer | null>(null);
  const [signerLoaded, setSignerLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const decryptionAttemptsRef = useRef(0);
  const lastAttemptTimeRef = useRef(0);
  const setCombinePublicKeyMutation = useSetCombinePublicKey();
  const chainId = useChainId();
  const { isAuthenticated } = useAuth();
  const mountedRef = useRef(true);

  const getSecureStorageKey = useCallback((address: string) => {
    const keys = Object.keys(secureLocalStorage);
    return keys.find((key) => key.includes(`${LOCAL_STORAGE_KEY}-${address}`));
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const createNewSigner = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      console.log('Creating new signer');
      const newKeyPair = generateRandomKeys();
      const newSigner = new Signer(newKeyPair.privateKey.toHex());

      const dataToEncrypt = JSON.stringify({
        privateKey: newKeyPair.privateKey.toHex(),
        publicKey: newKeyPair.publicKey.toHex(),
        address: newSigner.getAddress(),
      });

      const encryptedData = encrypt(dataToEncrypt, 'vDex', address as string);
      secureLocalStorage.setItem(`${LOCAL_STORAGE_KEY}-${address}`, encryptedData);
      await setCombinePublicKeyMutation.mutateAsync({
        chainID: chainId,
        publicKey: newKeyPair.publicKey.toHex(),
      });
      if (mountedRef.current) {
        setSigner(newSigner);
        setSignerLoaded(true);
        console.log('New signer created and stored');
      }
    } catch (error) {
      console.error('Error creating new signer:', error);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [address, chainId, setCombinePublicKeyMutation, isLoading]);

  const loadOrCreateSigner = useCallback(async () => {
    if (isLoading || !address) return;
    setIsLoading(true);

    const currentTime = Date.now();
    if (
      decryptionAttemptsRef.current >= MAX_DECRYPTION_ATTEMPTS &&
      currentTime - lastAttemptTimeRef.current < COOLDOWN_PERIOD
    ) {
      console.log('Max decryption attempts reached. Cooling down...');
      setIsLoading(false);
      return;
    }

    try {
      console.log('Attempting to load or create signer for address:', address);
      const storedData = secureLocalStorage.getItem(`${LOCAL_STORAGE_KEY}-${address}`);

      if (storedData) {
        console.log('Stored data found, attempting to decrypt');
        const decryptedData = decrypt(storedData as string, 'vDex', address as string);

        if (decryptedData) {
          console.log('Data decrypted successfully');
          const parsedData = safeJSONParse(decryptedData);

          if (parsedData && parsedData.privateKey) {
            console.log('Valid private key found, creating signer');
            const loadedSigner = new Signer(parsedData.privateKey);
            if (mountedRef.current) {
              setSigner(loadedSigner);
              setSignerLoaded(true);
            }
            decryptionAttemptsRef.current = 0;
            console.log('Signer loaded successfully');
          } else {
            console.error('Failed to parse decrypted data or missing privateKey');
            await createNewSigner();
          }
        } else {
          console.error('Decryption failed');
          decryptionAttemptsRef.current += 1;
          lastAttemptTimeRef.current = currentTime;
          if (decryptionAttemptsRef.current >= MAX_DECRYPTION_ATTEMPTS) {
            console.log('Max decryption attempts reached. Creating new signer.');
            await createNewSigner();
          }
        }
      } else {
        console.log('No stored data found, creating new signer');
        await createNewSigner();
      }
    } catch (error) {
      console.error('Error in loadOrCreateSigner:', error);
      decryptionAttemptsRef.current += 1;
      lastAttemptTimeRef.current = currentTime;
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [address, createNewSigner, isLoading]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key && event.key.includes(`${LOCAL_STORAGE_KEY}-${address}`)) {
        if (!event.newValue) {
          console.log('Signer data cleared from localStorage, recreating signer');
          setSignerLoaded(false);
          setSigner(null);
          createNewSigner();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [address, signerLoaded, loadOrCreateSigner, getSecureStorageKey]);

  useEffect(() => {
    if (isAuthenticated && address && !signerLoaded && !isLoading) {
      console.log('Triggering loadOrCreateSigner');
      loadOrCreateSigner();
    }
  }, [isAuthenticated, address, signerLoaded, isLoading, loadOrCreateSigner]);
  const getAddress = useCallback(() => signer?.getAddress() || '', [signer]);
  const getPublicKey = useCallback(() => signer?.getPublicKey(), [signer]);
  const getPublicNonces = useCallback(() => signer?.getPublicNonces(), [signer]);
  const multiSignMessage = useCallback(
    (msg: string, publicKeys: Key[], publicNonces: PublicNonces[], signerData: Signer) =>
      signerData?.multiSignMessage(msg, publicKeys, publicNonces),
    [],
  );

  const contextValue = React.useMemo(
    () => ({
      signer,
      signerLoaded,
      getAddress,
      getPublicKey,
      getPublicNonces,
      multiSignMessage,
    }),
    [signer, signerLoaded, getAddress, getPublicKey, getPublicNonces, multiSignMessage],
  );

  return <SignerContext.Provider value={contextValue}>{children}</SignerContext.Provider>;
};

export const useSigner = (): SignerContextProps => {
  const context = useContext(SignerContext);
  if (!context) {
    throw new Error('useSigner must be used within a SignerProvider');
  }
  return context;
};

import { GetExpectedPriceParams } from '../user/get-expected-price';
import { GetFundingRateParams } from './get-funding-rate';
import { GetTokenPriceParams } from './get-token-price';

export const tokenKeys = {
  all: ['token'] as const,

  supportedPairs: () => [...tokenKeys.all, 'supportedPairs'] as const,
  networkSupportedPairs: (network: number) => [...tokenKeys.supportedPairs(), network] as const,

  supportedTokens: () => [...tokenKeys.all, 'supportedTokens'] as const,
  networkSupportedTokens: (network: number, number: number, isLP?: boolean) =>
    [...tokenKeys.supportedTokens(), network, number, isLP] as const,

  prices: () => [...tokenKeys.all, 'price'] as const,
  price: (params: GetTokenPriceParams) => [...tokenKeys.prices(), params] as const,

  expectedPrices: () => [...tokenKeys.all, 'expectedPrices'] as const,
  expectedPrice: (params: GetExpectedPriceParams) =>
    [...tokenKeys.expectedPrices(), params] as const,

  fundingRates: () => [...tokenKeys.all, 'fundingRate'] as const,
  fundingRate: (params: GetFundingRateParams) => [...tokenKeys.fundingRates(), params] as const,
};

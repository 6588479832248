import * as CryptoJS from 'crypto-js';

export const generateSalt = (walletAddress: string, length: number = 16): string => {
  const cleanAddress = walletAddress.toLowerCase().replace(/^0x/, '');
  const seed = CryptoJS.SHA256(cleanAddress);
  const salt = CryptoJS.PBKDF2(seed, cleanAddress, {
    keySize: length / 4,
    iterations: 1000,
  });
  return salt.toString();
};

export const encrypt = (message: string, secretKey: string, walletAddress: string): string => {
  const salt: string = generateSalt(walletAddress);
  const key: CryptoJS.lib.WordArray = CryptoJS.PBKDF2(secretKey, salt, {
    keySize: 256 / 32,
    iterations: 1000,
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encrypted: CryptoJS.lib.CipherParams = CryptoJS.AES.encrypt(message, key, { iv: iv });

  // Combine the IV and the encrypted message
  const result = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  // Return the salt and the combined IV+ciphertext
  return JSON.stringify({ salt: salt, ciphertext: result });
};

export const decrypt = (
  encryptedData: string,
  secretKey: string,
  walletAddress: string,
): string | null => {
  try {
    // console.log('Decryption attempt started');
    // console.log('Encrypted data:', encryptedData);
    // console.log('Wallet address:', walletAddress);

    const { salt, ciphertext } = JSON.parse(encryptedData);
    // console.log('Retrieved salt:', salt);

    const key: CryptoJS.lib.WordArray = CryptoJS.PBKDF2(secretKey, salt, {
      keySize: 256 / 32,
      iterations: 1000,
    });
    // console.log('Generated key:', key.toString());

    // Separate the IV and the actual ciphertext
    const ciphertextWords = CryptoJS.enc.Base64.parse(ciphertext);
    const iv = ciphertextWords.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertextWords.words.splice(0, 4); // remove IV from the ciphertext
    ciphertextWords.sigBytes -= 16;

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertextWords } as any, key, { iv: iv });
    // console.log('Decryption successful');

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    // console.log('Decrypted string:', decryptedString);

    if (!decryptedString) {
      console.error('Decryption resulted in an empty string');
      return null;
    }
    return decryptedString;
  } catch (error) {
    console.error('Unexpected error in decrypt function:', error);
    if (error instanceof Error) {
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    return null;
  }
};

export const safeJSONParse = (str: string): any | null => {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('JSON parsing failed:', error);
    return null;
  }
};

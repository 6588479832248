export enum OrderSide {
  Buy = 1,
  Sell = 2,
}

export enum OrderType {
  Limit = 1,
  Market = 2,
}

export enum MarketOrderBy {
  Amount = 0,
  Total = 1,
}

export enum ORDER_STATUS {
  Invalid = -2,
  Pending = 0, // Pending order waiting for lock balance
  Canceled = -1, // Cancel order
  Fillable = 1, // Order waiting for exchange
  Filling = 2, // Order in exchange processing with another order
  Fulfill = 3, // Order is done
  PartiallyFilled = 4,
}

export const BOOLEAN = {
  TRUE: 1,
  FALSE: 0,
};

export const DECIMAL_COIN = {
  STABLE: 4,
  FNFT: 3,
};

export const PAGE_LIMIT = 10;

export const MAX_ITEM_ORDER_HISTORY = 10;

export const IS_STABLE_COIN = 0;

export enum TradingMethod {
  StellarOrderbook = 1,
  BSCOrderbook = 2,
  BSCPool = 4,
  PancakeswapPool = 8,
  CombinedOrderbook = TradingMethod.StellarOrderbook | TradingMethod.BSCOrderbook,
  All = TradingMethod.StellarOrderbook | TradingMethod.BSCOrderbook | TradingMethod.BSCPool,
}


export const USDT_MINIMUM_WITHDRAWAL = 10;
export const OTHER_TOKEN_MINIMUM_WITHDRAWAL = 0.005;

import { Key, PublicNonces } from '@borislav.itskov/schnorrkel.js';
import { useQueryClient } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import secureLocalStorage from 'react-secure-storage';
import { io, Socket } from 'socket.io-client';
import { decrypt, safeJSONParse } from 'src/helpers/encryption';
import { storeSignature } from 'src/helpers/save-signatures';
import { accessTokenIAO } from 'src/helpers/storage';
import { useAuth } from 'src/libs/rainbow';
import Signer from 'src/services/auth/key-pair';
import { orderKeys } from 'src/services/order/order-keys';
import { useAccount } from 'wagmi';
import { useSigner } from './SignerContext';

const URL = process.env.REACT_APP_SERVICE_SOCKET ?? '';

interface SocketContextProps {
  signatureSocket: Socket | null;
  isSignatureSocketInitialized: boolean;
  isBalanceSocketInitialized: boolean;
  userBalance: number;
  userBalanceAvailable: number;
  userLPBalance: number;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const [signatureSocket, setSignatureSocket] = useState<Socket | null>(null);
  // const [balanceSocket, setBalanceSocket] = useState<WebSocket | null>(null);
  const reconnectInterval = 3000; // 3 seconds
  const maxReconnectAttempts = 5;
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [userBalanceAvailable, setUserBalanceAvailable] = useState(0);
  const [userLPBalance, setUserLPBalance] = useState(0);
  const { getPublicNonces, getPublicKey, multiSignMessage, signer } = useSigner();
  const [isSignatureSocketInitialized, setIsSignatureSocketInitialized] = useState(false);
  const [isBalanceSocketInitialized, setIsBalanceSocketInitialized] = useState(false);
  const { isAuthenticated } = useAuth();
  const { address } = useAccount();
  const [isReconnecting, setIsReconnecting] = useState(false);
  const balanceSocket = useRef<WebSocket | null>(null);

  const closeWebSocket = () => {
    if (balanceSocket.current) {
      console.log('Closing WebSocket connection.');
      balanceSocket.current.close();
      balanceSocket.current = null;
      setIsReconnecting(false);
    }
  };

  const initWebSocket = useCallback(
    (token: string) => {
      console.log('Initializing WebSocket');
      if (isReconnecting) {
        closeWebSocket();
        return;
      }
      if (!balanceSocket.current) {
        const newWs = new WebSocket(`${URL}/api-gateway/v1/ws?accessToken=${token}`);
        balanceSocket.current = newWs;

        newWs.onopen = () => {
          console.log('WebSocket connection established.');
          setReconnectAttempts(0);
          const balanceRequest = {
            event: 'balance.update',
            params: {},
          };
          const lpRequest = {
            event: 'lp.balance.update',
            params: {},
          };
          newWs.send(JSON.stringify(balanceRequest));
          newWs.send(JSON.stringify(lpRequest));
        };

        newWs.onmessage = (event) => {
          // console.log('Message received:', event.data);
          const data = JSON.parse(event.data);

          if (data.event === 'balance.update' && data.success) {
            const balanceInfo = new BigNumber(data.result[0]?.balance || 0)
              .plus(data?.result[0]?.position_margins || 0)
              .plus(data?.result[0]?.open_orders || 0)
              .toNumber();

            setUserBalance(balanceInfo ?? 0);
            setUserBalanceAvailable(data.result[0].balance);
            if (balanceInfo !== undefined) {
              setIsBalanceSocketInitialized(true);
            }
          }
          if (data.event === 'lp.balance.update' && data.success) {
            const balanceInfo = data.result[0].balance;
            setUserLPBalance(balanceInfo ?? 0);
          }
        };

        newWs.onclose = () => {
          if (balanceSocket.current) {
            console.log('ws closed by server');
          } else {
            console.log('ws closed by app component unmount');
            return;
          }
          if (isReconnecting) {
            console.log('Reconnection in progress, ignoring close handler.');
            return;
          }
          setIsReconnecting(true);
          console.log('WebSocket connection closed. Attempting to reconnect...');
          setIsBalanceSocketInitialized(false);

          let reconnectCount = 0;
          const reconnectIntervalId = setInterval(() => {
            if (reconnectCount < 5) {
              console.log('Reconnecting attempt', reconnectCount + 1);
              attemptReconnect(token);
              reconnectCount++;
            } else {
              clearInterval(reconnectIntervalId);
            }
          }, reconnectInterval);
        };

        newWs.onerror = (error) => {
          console.error('WebSocket error:', error);
          newWs.close();
        };

        return () => {
          balanceSocket.current = null;
          newWs.close();
        };
      }
    },
    [URL, balanceSocket, isReconnecting],
  );

  const attemptReconnect = useCallback(
    (token: string) => {
      if (reconnectAttempts < maxReconnectAttempts) {
        setReconnectAttempts((prevAttempts) => {
          const newAttempts = prevAttempts + 1;
          console.log('Previous attempts:', newAttempts);
          return newAttempts;
        });
        setTimeout(() => {
          console.log(`Reconnecting attempt ${reconnectAttempts + 1}/${maxReconnectAttempts}...`);
          initWebSocket(token);
        }, reconnectInterval);
      } else {
        console.log('Max reconnect attempts reached. Please refresh the page to try again.');
        setIsReconnecting(false);
      }
    },
    [reconnectAttempts, maxReconnectAttempts, reconnectInterval, initWebSocket],
  );

  const initializeSocket = (token: string) => {
    if (signatureSocket) return;
    console.log('socket token', token, `${URL}/signature-service/ws/`);

    const newSocket = io(URL, {
      path: '/signature-service/ws/',
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSignatureSocket(newSocket);
    setIsSignatureSocketInitialized(true);
  };

  const generateNonceToSend = (data: any) => {
    const storedData = secureLocalStorage.getItem(`signerData-${address}`) as string;
    const decryptedData = decrypt(storedData, 'vDex', address as string);
    let privateKey;
    if (decryptedData) {
      const parsedData = safeJSONParse(decryptedData);
      console.log('🚀 ~ generateNonceToSend ~ parsedData:', parsedData);

      if (parsedData && parsedData.privateKey) {
        privateKey = parsedData.privateKey;
      } else {
        console.error('Failed to parse decrypted data or missing privateKey');
        // Handle this error case - maybe clear the corrupted data and generate new keys
      }
    } else {
      console.error('Decryption failed');
      // Handle this error case - maybe clear the corrupted data and generate new keys
    }
    // const { privateKey } = JSON.parse(decryptedData!);
    const localSigner = new Signer(privateKey!);
    signersData[data.id] = localSigner;
    const nonces = localSigner.getPublicNonces();
    // console.log('🚀 ~ onNewTrade ~ nonces:', nonces);
    const publicKey = localSigner.getPublicKey()?.toHex();
    // console.log('🚀 ~ onNewTrade ~ publicKey:', publicKey);
    const sigData = {
      publicKey: publicKey,
      publicNonce: {
        kPublic: localSigner.getPublicNonces()?.kPublic.toHex(),
        kTwoPublic: localSigner.getPublicNonces()?.kTwoPublic.toHex(),
      },
      signatureID: data.id,
    };

    return sigData;
  };
  let signersData: Signer[] = [];
  // console.log('🚀 ~ signersData:', signersData);
  useEffect(() => {
    if (signatureSocket) {
      const onConnect = () => {
        console.log('socket connect');
        signatureSocket.emit('onConnect', 'connected to server');
      };

      const onDisconnect = () => {};

      const onConnectReply = (data: any) => {
        console.log('socket onconnect reply', data, signatureSocket.id);
        const signatures = data?.signatures;

        if (signatures.length > 0) {
          signatures.map((sig: any) => {
            const sigData = generateNonceToSend(sig);
            console.log('🚀 ~ signatures.map ~ sigData:', sigData, signersData);
            signatureSocket.emit('exchangeNonce', sigData);
          });
        }
      };

      const onNewTrade = (data: any) => {
        const sigData = generateNonceToSend(data);

        queryClient.invalidateQueries({ queryKey: orderKeys.position(address!) }),
          // queryClient.invalidateQueries({ queryKey: userKeys.allBalances(address!) }),
          // console.log('🚀 ~ onNewTrade ~ sigData:', sigData);
          signatureSocket.emit('exchangeNonce', sigData);
      };

      const onExchangeNonceReply = (signRequestDto: any) => {
        // console.log(
        //   'socket onexchange',
        //   signRequestDto,
        //   signersData,
        //   signersData[signRequestDto.signatureID].getAddress(),
        //   signRequestDto.signatureID,
        // );
        // console.log('socket onexchange', signersData, signRequestDto?.signatureID);
        try {
          const nonces = {
            kPublic: Key.fromHex(signRequestDto.publicNonce.kPublic),
            kTwoPublic: Key.fromHex(signRequestDto.publicNonce.kTwoPublic),
          };
          const publicKey = Key.fromHex(signRequestDto.publicKey);
          const publicKeys = [publicKey, signersData[signRequestDto.signatureID].getPublicKey()];
          const platformNonce = signersData[signRequestDto.signatureID].getPublicNonces();
          const publicNonces = [nonces, platformNonce];
          const signature = multiSignMessage(
            signRequestDto.signatureData,
            publicKeys as Key[],
            publicNonces as PublicNonces[],
            signersData[signRequestDto.signatureID],
          );
          const signRes = {
            signature: signature!.signature.toHex(),
            publicKey: signersData[signRequestDto.signatureID].getPublicKey()!.toHex(),
            publicNonce: {
              kPublic: platformNonce!.kPublic.toHex(),
              kTwoPublic: platformNonce!.kTwoPublic.toHex(),
            },
            signatureID: signRequestDto.signatureID,
          };
          signatureSocket.emit('generateSignature', signRes);
        } catch (error) {}
      };

      const onGenerateSignatureReply = (data: any) => {
        // console.log('socket generatesignaturereply', data);
        signersData = [];
        storeSignature(JSON.stringify(data), address!);
        // localStorage.setItem('lastSignature', JSON.stringify(data));
      };

      signatureSocket.on('connect', onConnect);
      signatureSocket.on('disconnect', onDisconnect);
      signatureSocket.on('onConnectReply', onConnectReply);
      signatureSocket.on('new-trade-events', onNewTrade);
      signatureSocket.on('exchangeNonceReply', onExchangeNonceReply);
      signatureSocket.on('generateSignatureReply', onGenerateSignatureReply);

      // socket.on('message', onListen);
      return () => {
        signatureSocket.off('connect', onConnect);
        signatureSocket.off('disconnect', onDisconnect);
        signatureSocket.off('onConnectReply', onConnectReply);
        signatureSocket.off('new-trade-events', onNewTrade);
        signatureSocket.off('exchangeNonceReply', onExchangeNonceReply);
        signatureSocket.off('generateSignatureReply', onGenerateSignatureReply);
        // socket!.off('message', onListen);
      };
    }
  }, [signatureSocket, signer]);

  useEffect(() => {
    const token = localStorage.getItem(accessTokenIAO);
    // console.log('🚀 ~ useEffect ~ token:', token);
    if (token) {
      initializeSocket(token);
      initWebSocket(token);
    } else {
      const handleStorageChange = () => {
        const newToken = localStorage.getItem(accessTokenIAO);
        if (newToken && !isSignatureSocketInitialized) {
          initializeSocket(newToken);
          initWebSocket(newToken);
        }
      };

      window.addEventListener('storage', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, [isSignatureSocketInitialized, isAuthenticated, initWebSocket]);

  useEffect(() => {
    if (!isAuthenticated && balanceSocket.current) {
      closeWebSocket();
    }
  }, [isAuthenticated, balanceSocket]);

  return (
    <SocketContext.Provider
      value={{
        signatureSocket,
        isSignatureSocketInitialized,
        isBalanceSocketInitialized,
        userBalance,
        userBalanceAvailable,
        userLPBalance,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

import { CSSProperties } from 'react';
import './styles.scss';
import AppCheckbox, { IAppCheckbox } from '../21.checkbox';

interface IAppLabelCheckBox extends IAppCheckbox {
  label: string;
  isCircle?: boolean;
  textRightPlace?: boolean;
  helperText?: string;
  customRootClass?: string;
  color?: string;
}

const AppLabelCheckBox = ({
  label,
  customRootClass,
  color,
  helperText,
  textRightPlace,
  isCircle,
  ...props
}: IAppLabelCheckBox) => {
  const customStyle = color ? { '--checkbox-color': color } : { '--checkbox-color': '#FBDB28' };

  return (
    <div
      className={`app-label-checkbox ${isCircle && 'is-circle'} ${textRightPlace ? 'flex-row-reverse' : 'flex-row'} flex gap-2 items-center ${customRootClass}`}
    >
      <div
        className={`flex flex-col flex-1 ${props.appDisabled && 'text-[var(--color-neutral-400)]'}`}
      >
        <span>{label}</span>
        {helperText && <span>{helperText}</span>}
      </div>
      <AppCheckbox style={customStyle as CSSProperties} {...props} />
    </div>
  );
};

export default AppLabelCheckBox;

import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { useAccount } from 'wagmi';

export interface FaucetRequestData {
  chainID: number;
}

export interface FaucetResponse {
  data: any;
}

export const callFaucet = async (data: FaucetRequestData) => {
  const response = await apiClient.post<FaucetResponse>('api/v1/faucets', data, {
    baseURL: process.env.REACT_APP_SIGNATURE_SERVICE_API_ENDPOINT,
  });
  console.log('🚀 ~ callFaucet ~ response:', response);

  return response.data;
};

export type useFaucetOptions<TError = Error, TContext = unknown> = MutationOptions<
  FaucetResponse,
  TError,
  FaucetRequestData,
  TContext
>;

export const useFaucet = <TError = Error, TContext = unknown>(
  options?: useFaucetOptions<TError, TContext>,
) => {
  const queryClient = useQueryClient();
  const { address: userAddress } = useAccount();
  return useMutation({
    mutationFn: callFaucet,
    onSuccess() {},
    ...options,
  });
};

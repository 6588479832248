import { Dropdown, Menu, Space } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowDownMenuIcon } from 'src/assets/icons';
import { THEME_MODE } from 'src/interfaces/theme';
import { IMenuItem } from '.';

const MenuItem: React.FC<IMenuItem> = ({ label, path, subMenus }) => {
  const userTheme = useSelector((state: any) => state.theme);
  const { push } = useHistory();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  let listSubMenu: ItemType[] = [];

  // const isActiveLink = !!subMenus?.find((subMenuItem, i) =>
  //   pathname.includes(subMenuItem.path ?? ''),
  // );

  const renderMenuItem = () => {
    if (subMenus) {
      listSubMenu = subMenus.map((subItem, i) => {
        const isOpenBlankTab = false;

        const renderLabel = () => {
          if (isOpenBlankTab) {
            return (
              <div
                onClick={() => {
                  window.location.replace(subItem.path ?? '');
                }}
              >
                {userTheme === THEME_MODE.DARK ? (
                  <span className="hover:underline text-white">{subItem.label}</span>
                ) : (
                  <span className="hover:underline text-black">{subItem.label}</span>
                )}
              </div>
            );
          }

          return (
            <Link
              className={`${userTheme === THEME_MODE.DARK ? 'text-white' : 'text-black'}`}
              to={subItem.path}
            >
              {userTheme === THEME_MODE.DARK ? (
                <span className="hover:underline text-white">{subItem.label}</span>
              ) : (
                <span className="hover:underline text-black">{subItem.label}</span>
              )}
            </Link>
          );
        };

        return {
          key: i,
          label: renderLabel(),
        };
      });

      return (
        <Dropdown
          open={isOpenDropdown}
          onOpenChange={(open) => setIsOpenDropdown(open)}
          overlay={<Menu items={listSubMenu} />}
          overlayClassName={`dropdown ${userTheme}`}
          placement="bottom"
        >
          <Space>
            {userTheme === THEME_MODE.DARK ? (
              <span className="hover:underline text-white">{label}</span>
            ) : (
              <span className="hover:underline text-black">{label}</span>
            )}
            <img src={ArrowDownMenuIcon} alt="" />
          </Space>
        </Dropdown>
      );
    }

    return (
      <div
        className={` text-[18px] font-semibold cursor-pointer`}
        onClick={() => {
          push(path ?? '');
        }}
      >
        {userTheme === THEME_MODE.DARK ? (
          <span className="hover:underline text-white">{label}</span>
        ) : (
          <span className="hover:underline text-black">{label}</span>
        )}
        {path?.includes(label) && <div className="w-8 h-0.5  bg-blue-700 "></div>}
      </div>
    );
  };

  return (
    // <div className={`menu-item ${isActiveLink ? 'menu-item--active' : ''}`}>{renderMenuItem()}</div>
    <div className={` ${isOpenDropdown ? 'menu-item--active' : ''}`}>{renderMenuItem()}</div>
  );
};

export default MenuItem;

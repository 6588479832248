import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { useAuth } from 'src/libs/rainbow';
import { QueryOptions } from 'src/libs/react-query';
import { useAccount } from 'wagmi';
import { orderKeys } from './order-keys';
import { Direction } from '../user/get-expected-price';

export interface UserPositionDto {
  user_id: string;
  leverage_id: string;
  leverage_factor: number;
  direction: Direction;
  position_id: string;
  entry_price: number;
  current_price: number;
  token_id: string;
  amount: number;
  funding: number;
  margin: number;
  stop_loss_price: number;
  take_profit_price: number;
  leverage_type: string;
  close_all?: string;
  tp_sl?: string;
  pnl?: string;
  liquidation_price: number;
}

export type GetUserPositionResponse = UserPositionDto;

export const getPosition = async () => {
  const { data } = await apiClient.get('/v1/liquidate/getUserPosition');
  // console.log('🚀 ~ getPosition ~ data:', data);

  return data;
};

export type UseGetUserPositionOptions<TData, TError> = QueryOptions<
  GetUserPositionResponse,
  TError,
  TData,
  ReturnType<typeof orderKeys.position>
>;

export const useGetPosition = <TData = GetUserPositionResponse, TError = Error>(
  options?: UseGetUserPositionOptions<TData, TError>,
) => {
  const { isAuthenticated } = useAuth();
  const { address: userAddress } = useAccount();

  return useQuery({
    queryKey: orderKeys.position(userAddress!),
    queryFn: getPosition,
    enabled: isAuthenticated,
    ...options,
  });
};

import { generateUserQueryKeys } from 'src/libs/react-query';
import { Address } from 'viem';
import { ClaimWithdrawalTradeReqData } from './claim-withdrawal-trade';
import { GetDailyReferralStatsParams } from './get-daily-referral-stats';
import { GetDailyVNTStatsParams } from './get-daily-vnt-stats';
import { GetInvitedUsersParams } from './get-invited-users';
import { GetLPHistoryParams } from './get-lp-history';
import { GetTradingHistoryParams } from './get-trading-history';
import { GetUserLPChartParams } from './get-user-lp-chart';
import { GetWithdrawableBalanceParamsWithAddress } from './get-withdrawable-balance';
import { GetWithdrawalAmountTradeParams } from './get-withdrawal-amount-trade';
import { WithdrawReqDataTrade } from './request-withdraw-trade';
import { GetUserPortfolioParams } from './get-user-portfolio';
import { GetVMMChartsParams } from './get-vmm-charts';
import { GetLPRewardsParams } from './get-lp-rewards';
import { GetFundingHistoryTradeParams } from './get-funding-history-trade';

export const userKeys = {
  all: ['user'] as const,

  balances: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'balance'),
  allBalances: (walletAddress: Address) => [...userKeys.balances(walletAddress), 'all'] as const,
  portfolio: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'portfolio'),
  allPortfolio: (walletAddress: Address) => [...userKeys.portfolio(walletAddress), 'all'] as const,
  withdrawSchnorrSig: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'withdrawSchnorrSig'),
  allWithdrawSchnorrSig: (walletAddress: Address) =>
    [...userKeys.withdrawSchnorrSig(walletAddress), 'all'] as const,
  combineSignatures: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'combineSignatures'),
  allcombineSignatures: (walletAddress: Address) =>
    [...userKeys.combineSignatures(walletAddress), 'all'] as const,

  lpRewards: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'lpRewards'),
  allLpRewards: (walletAddress: Address, params?: GetLPRewardsParams) =>
    [...userKeys.lpRewards(walletAddress), params || undefined] as const,

  withdrawableBalance: () => [...userKeys.all, 'withdrawableBalance'] as const,
  allwithdrawableBalance: (params: GetWithdrawableBalanceParamsWithAddress) =>
    [...userKeys.withdrawableBalance(), params] as const,

  lpHistory: () => [...userKeys.all, 'lpHistory'] as const,
  lpHistoryAll: (params?: GetLPHistoryParams) =>
    [...userKeys.lpHistory(), params || undefined] as const,

  tradingHistory: () => [...userKeys.all, 'tradingHistory'] as const,
  tradingHistoryAll: (params: GetTradingHistoryParams) =>
    [...userKeys.tradingHistory(), params] as const,

  stats: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'stats'),
  statsAll: (walletAddress: Address) => [...userKeys.stats(walletAddress), 'all'] as const,

  invitedUsers: () => [...userKeys.all, 'invitedUsers'] as const,
  invitedUsersAll: (params: GetInvitedUsersParams) => [...userKeys.invitedUsers(), params] as const,

  dailyReferralStats: () => [...userKeys.all, 'dailyReferralStats'] as const,
  dailyReferralStatsAll: (params: GetDailyReferralStatsParams) =>
    [...userKeys.dailyReferralStats(), params] as const,

  dailyVNTStats: () => [...userKeys.all, 'dailyVNTStats'] as const,
  dailyVNTStatsAll: (params: GetDailyVNTStatsParams) =>
    [...userKeys.dailyVNTStats(), params] as const,
  userInfo: (userId: string) => [...userKeys.all, 'userInfo', userId] as const,
  acceptTerm: (userId: string) => [...userKeys.all, 'acceptTerm', userId] as const,

  lpChart: () => [...userKeys.all, 'lpChart'] as const,
  allLpChart: (params: GetUserLPChartParams) => [...userKeys.lpChart(), params] as const,

  mmLpChart: () => [...userKeys.all, 'mmLpChart'] as const,
  allMMLpChart: (params: GetUserLPChartParams) => [...userKeys.mmLpChart(), params] as const,

  requestWithdrawTrade: () => [...userKeys.all, 'requestWithdrawTrade'] as const,
  allRequestWithdrawTrade: (params: WithdrawReqDataTrade) =>
    [...userKeys.requestWithdrawTrade(), params] as const,

  cancelWithdrawalTrade: () => [...userKeys.all, 'cancelWithdrawalTrade'] as const,
  allCancelWithdrawalTrade: (id: number) => [...userKeys.cancelWithdrawalTrade(), id] as const,

  getWithdrawalAmountTrade: () => [...userKeys.all, 'getWithdrawalAmountTrade'] as const,
  allGetWithdrawalAmountTrade: (params: GetWithdrawalAmountTradeParams) =>
    [...userKeys.getWithdrawalAmountTrade(), params] as const,

  claimWithdrawalTrade: () => [...userKeys.all, 'claimWithdrawalTrade'] as const,
  allClaimWithdrawalTrade: (data: ClaimWithdrawalTradeReqData) =>
    [...userKeys.claimWithdrawalTrade(), data] as const,

  tradeStats: () => [...userKeys.all, 'tradeStats'] as const,
  allTradeStats: () => [...userKeys.tradeStats(), 'all'] as const,

  vmmTradeStats: () => [...userKeys.all, 'vmmTradeStats'] as const,
  allVmmTradeStats: () => [...userKeys.vmmTradeStats(), 'all'] as const,

  lpState: () => [...userKeys.all, 'lpState'] as const,
  allLpState: () => [...userKeys.lpState(), 'all'] as const,

  userPortfolio: () => [...userKeys.all, 'userPortfolio'] as const,
  allUserPortfolio: (params?: GetUserPortfolioParams) =>
    [...userKeys.userPortfolio(), params || undefined] as const,

  vmmCharts: () => [...userKeys.all, 'vmmCharts'] as const,
  allVmmCharts: (params: GetVMMChartsParams) => [...userKeys.vmmCharts(), params] as const,

  fundingHistoryTrade: () => [...userKeys.all, 'fundingHistoryTrade'] as const,
  allFundingHistoryTrade: (params: GetFundingHistoryTradeParams) =>
    [...userKeys.fundingHistoryTrade(), params] as const,
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';
import { userKeys } from './user-keys';

export type AcceptTermResponse = {
  message: string;
};

export const acceptTerm = async (userId: string) => {
  const { data } = await apiClient.post(`/v1/user/acceptTerms`);
  return data;
};

export type UseAcceptTermOptions<TError = Error, TContext = unknown> = MutationOptions<
  AcceptTermResponse,
  TError,
  string,
  TContext
>;
export const useAcceptTerm = <TError = Error, TContext = unknown>(
  options?: UseAcceptTermOptions<TError, TContext>,
) => {
  const queryClient = useQueryClient();
  const userId = localStorage.getItem('userId') ?? '';
  return useMutation({
    mutationFn: acceptTerm,
    onSettled() {
      const invalidateUserInfoPromise = queryClient.invalidateQueries({
        queryKey: userKeys.userInfo(userId),
      });
      return Promise.all([invalidateUserInfoPromise]);
    },
    ...options,
  });
};

import { useState } from 'react';
import { Modal } from 'antd';
import { useAppDispatch } from 'src/store';
import { setModalConfirmAdjustLeverage, setModalLeverageOpen } from 'src/store/actions/modal';
import AppLabelCheckBox from '../../20.label-checkbox/indes';
import { LoadingButton } from '../../ui/button';

interface ModalConfirmAdjustLeverageProps {
  isOpen: boolean;
  onClose?: () => void;
}

function ModalConfirmAdjustLeverage({ isOpen, onClose }: ModalConfirmAdjustLeverageProps) {
  const dispatch = useAppDispatch();
  const [isAccept, setIsAccept] = useState(false);

  const handleConfirm = () => {
    if (isAccept) {
      localStorage.setItem(`adjustLeverageConfirm`, 'false');
    }

    dispatch(setModalConfirmAdjustLeverage(false));
    onClose?.();

    dispatch(setModalLeverageOpen(true));
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      className="z-10 font-montserrat !w-[420px]"
      closable={false}
    >
      <div
        className={`terms-of-use text-element-primary flex flex-col gap-6 justify-center items-center`}
      >
        <div className="flex flex-col w-full md:gap-0 gap-2">
          <span className="md:text-2xl text-xl font-semibold text-center font-Transducer">
            ADJUST LEVERAGE
          </span>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-sm text-center">
            Orders and positions of the same trading pair apply to the same leverage. Modifying
            leverage under a position will affect the liquidity price.
          </p>
          <p className="text-sm text-center">Please proceed with caution.</p>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <AppLabelCheckBox
            onChange={(e) => {
              setIsAccept(e.target.checked);
            }}
            textRightPlace
            label="Do not show again"
            customRootClass="!font-medium w-full text-sm"
          />
        </div>
        <div className="flex justify-center w-full gap-2">
          <LoadingButton
            onClick={onClose}
            // className="bg-[#E1E3E7] dark:bg-[#1F2937] dark:text-white flex flex-1"
            className="flex flex-1 bg-transparent border-gray-600 dark:border-gray-700"
            variant="outline"
          >
            <p className="font-semibold md:text-[16px] text-sm uppercase">{'Cancel'}</p>
          </LoadingButton>
          <LoadingButton
            onClick={handleConfirm}
            className="flex flex-1 w-full bg-[#4C40E6] dark:text-white hover:bg-[#4d40fb]"
          >
            <p className="font-semibold md:text-[16px] text-sm uppercase">{'Confirm'}</p>
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmAdjustLeverage;

import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';

export interface UpdateChainIdDto {
  chainId: number;
}

export interface UpdateChainIdResponse {
  data: any;
}

export const updateChainId = async (updateChainIdDto: UpdateChainIdDto) => {
  const response = await apiClient.put('/v1/user/updateChainId', updateChainIdDto);

  return response.data;
};

export type UseUpdateChainIdOptions<TError = Error, TContext = unknown> = MutationOptions<
  UpdateChainIdResponse,
  TError,
  UpdateChainIdDto,
  TContext
>;

export function useUpdateChainId<TError = Error, TContext = unknown>(
  options?: UseUpdateChainIdOptions<TError, TContext>,
) {
  return useMutation({
    mutationFn: updateChainId,
    ...options,
  });
}

import { Pair } from 'src/services/token/get-supported-tokens';
import { Address, isAddressEqual } from "viem";
export const getActiveToken = (supportedTokens: Pair[], activeTokenAddress: Address) => {
  // console.log('tokens', activeTokenAddress, supportedTokens);
  if (supportedTokens.length < 1 || !activeTokenAddress) {
    return;
  }
  const token = supportedTokens.find(({ address }) => isAddressEqual(address, activeTokenAddress));

  return token;
};
